@charset "UTF-8";
/*! このcssはsass（sass/同名の.scssファイル）からコンパイルされています。修正時はこのcssを直接いじらないようにお願いします */

@import "_setting.scss";


/* CSS TOP!!!!???  */

/************************************

MAIN IMAGE

************************************/
/**** スライドショーありの場合 ***/
#mainimage {
position: relative;
width: 100%;
//height: 0;
//padding-top: 46.66%;
margin-bottom: 50px;
margin-top: 100px;
@include media-tab { 
margin-bottom: 30px;
margin-top: 0;
}
}


//************************************

//#top_mainimage

//************************************/
#top_mainimage{
width:100%;
height:60vh;
@media  screen and (max-width:960px) and (orientation: landscape) {
/* 横向きの場合のスタイル */
width:100%;
height:40vh;
}
@media  screen and (max-width:960px) and (orientation: portrait) {
/* 縦向きの場合のスタイル */
width:100%;
height:40vw;
}
@include media-sp { 
width:100%;
height:90vw;
}
img{
width:100%;
}
a{
text-decoration: none;
}
.waku{
position: relative;
overflow: hidden;
width:100%;
height:100vh;
background-position: center;
background-size: cover;
background-repeat: no-repeat;
width:100%;
height:60vh;
@media  screen and (max-width:960px) and (orientation: landscape) {
/* 横向きの場合のスタイル */
width:100%;
height:40vh;
}
@media  screen and (max-width:960px) and (orientation: portrait) {
/* 縦向きの場合のスタイル */
width:100%;
height:40vw;
}
@include media-sp { 
width:100%;
height:90vw;
}
}
.text{
position: absolute;
right:10%;
top:25%;
background-color: rgba( 223, 154, 41, 0.80 );
padding:30px 30px 20px;
border-radius: 30px;
width:300px;
@include media-tab { 
width:240px;
top:20%;
padding:15px;
}
@include media-sp { 
top:auto;
bottom:5%;
left:0;
right:0;
margin:auto;
}
h2{
color:#fff;
font-family: $mincho;
font-weight: normal;
font-size: 50px;
line-height: 1.2;
letter-spacing: 0;
text-align: center;
@include media-tab { 
font-size: 30px;
}
a{
color:#fff;
}
}
.eng{
font-family: $eng2;
font-size: 21px;
text-align: center;
color:#fff;
@include media-tab { 
font-size: 16px;
}
}
ul{
margin:10px 0 0;
display: flex;
-webkit-flex-wrap: wrap; 
flex-wrap:  wrap;
}
li{
width:48%;
margin:1%;
background-color: #fff;
border-radius: 2em;
text-align: center;
font-size: 14px;
font-weight: 500;
transition: 0.3s;
@include media-tab { 
font-size: 12px;
}
&.full{
width:98%;
}
&:hover{
opacity: 0.7;
}
a{
display: block;
padding:10px;
color:$main-color;
&:hover{
color:$main-color;
}
}
}
}//.text
.nashi{
background-image: url("../images/mainimage-nashi.jpg");
@media only screen and (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 1.5dppx){
background-image: url("../images/mainimage-nashi@2x.jpg");
}
.text{
background-color: rgba( 223, 154, 41, 0.80 );
}
}
.budo{
background-image: url("../images/mainimage-budo.jpg");
@media only screen and (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 1.5dppx){
background-image: url("../images/mainimage-budo@2x.jpg");
}
.text{
background-color: rgba( 146, 90, 142, 0.80 );
}
}
.ichigo{
background-image: url("../images/mainimage-ichigo.jpg");
@media only screen and (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 1.5dppx){
background-image: url("../images/mainimage-ichigo@2x.jpg");
}
.text{
background-color: rgba( 210, 89, 103, 0.80 );
}
}
.ringo{
background-image: url("../images/mainimage-ringo.jpg");
@media only screen and (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 1.5dppx){
background-image: url("../images/mainimage-ringo@2x.jpg");
}
.text{
background-color: rgba( 210, 89, 103, 0.80 );
}
}
.momo{
background-image: url("../images/mainimage-momo.jpg");
@media only screen and (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 1.5dppx){
background-image: url("../images/mainimage-momo@2x.jpg");
}
.text{
background-color: rgba( 210, 89, 103, 0.80 );
}
}
.sumomo{
background-image: url("../images/mainimage-sumomo.jpg");
@media only screen and (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 1.5dppx){
background-image: url("../images/mainimage-sumomo@2x.jpg");
}
.text{
background-color: rgba( 210, 89, 103, 0.80 );
}
}



/* Dots */
.slick-dotted.slick-slider
{
    margin-bottom: 30px;
}

.slick-dots
{
z-index: 3;
bottom:-30px;
}

}///#top_mainimage

/**** スライドショーなしの場合 ***/
#mainimage #no_slideshow {
position: absolute;
top: 0;
left: 0;
width: 100%;
}

/************************************

header

************************************/
#top #menu_home a {
color: #343333;
text-decoration: none;
border-bottom: 2px solid #ede39b;
}
/************************************

MAIN COMMON

************************************/
#main section {
transition: 2s;
}

//************************************

// #top_news

//************************************/
#top_news{

.wrapper{
//display: flex;
//-webkit-flex-wrap: wrap; 
//flex-wrap:  wrap;
max-width:720px;
margin:80px auto 60px;
@include media-tab { 
margin:50px auto ;
}
@include media-sp { 
display: block;
}
}

.news_list{
//width:calc(100% - 240px - 4%);
text-align: left;
margin:0 4% 0 0 ;
//max-width: 640px;
@include media-tab { 
width:calc(100% - 200px - 4%);
}
@include media-sp { 
width:100%;
margin:0 auto 20px;
}

h2{
font-size: 16px;
margin:0 0 10px;
span{
font-family: $eng;
font-weight: 400;
font-size: 40px;
margin:0 10px 0 0;
display: inline-block;
}
a{
color:$main-color;
text-decoration: none;
&:hover{
color:$key-color5;
}
}
}
dl{
display: flex;
-webkit-flex-wrap: wrap; 
flex-wrap:  wrap;
}
dt,dd{
padding:10px 0;
border-bottom: 1px dotted #ccc;

}
dt{
width:100px;
color:$key-color5;
font-size: 14px;
@include media-tab { 
width:70px;
font-size: 12px;
}
}
dd{
width:calc(100% - 100px);
@include media-tab { 
font-size: 15px;
width:calc(100% - 70px);
}
@include media-sp { 

}
a{
color:$main-color;
text-decoration: none;
&:hover{
color:$key-color5;
}
}
}
}

.banner{
width:240px;
@include media-tab { 
width:200px;
}
@include media-sp { 
width:280px;
margin:0 auto;
}
.ichigogari{
h3{
font-size: 14px;
margin:0 0 7px;
line-height: 1.3;
//color:#000;
}
}
.bt_info{

.bt{
//width:31.3%;
//margin:0 1%;
background: #a90329; 
background: -moz-linear-gradient(top, #a90329 0%, #8f0222 44%, #6d0019 100%);
background: -webkit-linear-gradient(top, #a90329 0%,#8f0222 44%,#6d0019 100%);
background: linear-gradient(to bottom, #a90329 0%,#8f0222 44%,#6d0019 100%); 
border-radius: 7px;
@include media-tab { 
width:100%;
margin:0 auto 10px;
}
}
a{
display: block;
//max-width:550px;
//width:90%;
//margin:15px auto 0;
width:100%;
background: transparent;
padding:55px 5px 10px 5px;
color:#fff;
text-decoration: none;
position: relative;
box-sizing: border-box;
transition:.5s;
@include media-sp { 
padding:40px 10px 10px;
}
&:hover{
opacity:.8;
}
&::before{
position: absolute;
top: 10px;
left: 0;
right:0;
margin:auto;
font-size: 40px;
width:40px;
height:40px;
line-height: 1;
color:#fff;
font-family: 'FontAwesome';
content: "\f05a";
@include media-sp { 
font-size: 24px;
width:24px;
height:24px;
}
}
h3{
font-size: 14px;
color:#fff;
line-height: 1.3;
@include media-tab { 
font-size: 13px;
}
@include media-sp { 
font-size: 15px;
}
}
p{
font-size: 80%;
font-weight: bold;
@include media-sp { 
font-size: 80%;
}
}
}
}//.bt_info
}
}

/************************************

top_ueue

************************************/
#top_ueue {
padding: 0px 5%;
@include media-tab { 
padding: 0px 5%;
}
}
#top_ueue .red{
color:#ff0000;
}


#top_ueue .banner {
width: 100%;
max-width: 948px;
margin: 0 auto 50px;
@include media-tab { 
width:100%;

}
p{
@include media-tab { 
font-size: 90%;
}
}
img{
margin-bottom: 10px;
}
}

#top_ueue .banner a:hover {
opacity: 0.7;
}

#top_ueue .banner  {


.hanbai_yotei{
margin:0 auto 20px;
background:#EFE8DB;
max-width:520px;
border-radius: 20px;
padding:15px;
box-sizing: border-box;
@include media-tab { 
max-width:100%;
border-radius: 10px;
}

ul{
display: flex;
-webkit-flex-wrap: wrap; 
flex-wrap:  wrap;
justify-content:center; 
li{
background-color: #fff;
padding:5px 20px;
border-radius: 2em;
margin:0 5px;
@include media-tab { 
font-size: 15px;
}
}
}
h3{
//color:red;
font-weight:600;
margin:0 0 ;
font-size:100%;
}
h4{
margin:15px 0 10px;
font-size:100%;
}
table {
border:0;
border-collapse: collapse;
margin:0 auto;
border:1px solid #ccc;
text-align: left;
font-size: 90%;
th,td{
padding:10px;
border-right:1px solid #ccc;
border-bottom:1px solid #ccc;
vertical-align: top;
}
th{
background-color: beige;
white-space: nowrap;
}
}
}//.hanbai_yotei

}





/************************************

ABOUT

************************************/
#top_ue {
background: url(../images/back_beige.jpg);
}
#about {
}
#about2 {
background: url(../images/about_back.jpg) no-repeat center top;
background-size: 100%;
padding-top: 80px;
@include media-tab { 
background: url(../images/about_back-sp.jpg) no-repeat center top;
padding-top: 50px;
}
}
#about .wrapper {
background: #fff;
width: 920px;
padding: 50px 0;
@include media-tab { 
width: 90%;
padding: 40px 0;
}
}
#about .shadow {
background: url(../images/shadow_l.png) no-repeat center top;
background-size: contain;
height: 35px;

}
#about .title{
display: inline-block;
}
#about p {
font-size: 100%;
line-height: 180%;
margin-bottom: 30px;
@include media-tab { 
font-size: 90%;
margin: 0 10% 30px 10%;
text-align: left;
}
}
#about .bt {
@include media-tab { 
margin: 0 7%;
}
}


/************************************

NEWS #top_blog

************************************/
#top_blog {
padding: 80px 0 40px 0;
@include media-tab { 
padding: 40px 0 50px 0;
}
}
#top_blog .title  {
display: inline-block;
}

#top_blog .list {
display: block; /* before Android4.3 iOS6:Safari */
display: -ms-flexbox; /*IE10*/
display: -webkit-flex; /* Safari */
display: flex;
-webkit-flex-wrap: wrap; /* Safari */
flex-wrap: wrap;
-webkit-justify-content: space-between; /* Safari */
justify-content: space-between;
margin:0 0 20px;
@include media-sp { 
margin: 0 5%;
}
}
#top_blog .list article {
width: 23%;
background: url(../images/news_back.png) no-repeat center bottom;
background-size: 100%;
padding-bottom: 32px;
@include media-sp { 
width: 100%;
margin: 0 !important;
background: url(../images/shadow_s.png) no-repeat center bottom;
}
}
#top_blog .list article .waku {
padding: 10px;
@include media-tab { 
background: #fff;
padding: 7%;
}
}
#top_blog .list article .image {
position: relative;
}
/* Flashing */
#top_blog .list article .image:hover img {
opacity: 1;
-webkit-animation: flash 1.5s;
animation: flash 1.5s;
}
#top_blog .list article .image .cat{
position: absolute;
right: 0;
bottom: 0;
display: flex;
-webkit-flex-wrap: wrap; 
flex-wrap:  wrap;
justify-content: flex-end;
@include media-tab { 
display: none;
}
@include media-sp { 
display: block;
}
}
#top_blog .list article .image .cat a {
background: #ede39b;
color: #343333;
font-size: 11px;
padding: 3px 4px;
text-decoration: none;
font-weight: 500;
transition: 0.5s;
margin:2px 0 0 2px;

}
#top_blog .list article .image .cat a:hover {
background-color: #dfcd4d;
}
#top_blog .list article .day {
text-align: left;
color: #d17e00;
font-size: 80%;
padding: 10px 0 0px 0;
}
#top_blog .list article h3 {
text-align: left;
font-size: 90%;
padding-bottom: 5px;
}
#top_blog .list article h3 a {
color: #343333;
text-decoration: none;
}
#top_blog .list article h3 a:hover {
color: #d17e00;
}
#top_blog .bt {
@include media-tab { 
margin: 0 5%;
}
}
/************************************

ITEM

************************************/
#item {
padding: 70px 0;
@include media-tab { 
padding: 40px 0 40px 0;
}
}
#item .wrapper {
@include media-sp { 
width:84%;
}
}
#item .title_back {
background: url(../images/item_t_back.png) no-repeat center top;

height: 70px;
width: 360px;
margin: 0 auto 40px auto;
@include media-tab { 
background: none;

height: auto;
width: auto;
margin: 0 auto 0px auto;
}
}

#item .title{
display: inline-block;
}

#item p.lead {
font-size: 90%;
line-height: 180%;
margin-bottom: 40px;
width:100%;
text-align: center;
@include media-tab { 
display: inline-block;
text-align: left;
}
}
#item .list {
display: block; /* before Android4.3 iOS6:Safari */
display: -ms-flexbox; /*IE10*/
display: -webkit-flex; /* Safari */
display: flex;
-webkit-flex-wrap: wrap; /* Safari */
flex-wrap: wrap;
-webkit-justify-content: space-between; /* Safari */
justify-content: center;
margin-bottom: 40px;
@include media-sp { 
margin-bottom: 0;
}
}
#item .list article {
width: 29.3%;
margin:0 2%;
@include media-sp { 
width: 100%;
margin: 0 0 25px 0 !important;

}
}
#item .image{
border-radius: 15px;
overflow: hidden;
}
#item .image:hover img {
opacity: 1;
-webkit-animation: flash 1.5s;
animation: flash 1.5s;
}
#item a {
color: #343333;
text-decoration: none;
transition: 0.3s;
}
#item a:hover {
color: #dfcd4d;
}
#item h3 {
font-size: 110%;
padding: 15px 0 8px 0;
@include media-tab { 
font-size: 110%;
padding: 10px 0 5px 0;
}
}

#item article p {
font-size: 90%;
text-align: left;
display: inline-block;
@include media-tab {
font-size: 80%;
}
}


#item .bt {
@include media-tab { 
margin: 0 5%;
}
a{
display: inline-block;
width:240px;
margin:0 10px;
color:#fff;
@include media-sp { 
margin:10px auto;
display: block;
}
&:hover {
color: #fff;
}
}
}

/************************************

RECIPE

************************************/
#recipe {
padding: 40px 0 40px 0;
background: #ede39b;
}
#recipe .wrapper {

@include media-sp { 
width: 84%;
}
}
#recipe .title_back {
background: url(../images/recipe_t_back.png) no-repeat center top;

height: 70px;
width: 360px;
margin: 0 auto 40px auto;
@include media-tab { 
background: none;

height: auto;
width: auto;
margin: 0 auto 0px auto;
}
}

#recipe .title {
display: inline-block;
}
#recipe p.lead {
font-size: 90%;
line-height: 180%;
margin-bottom: 40px;
@include media-sp { 

text-align: left;
}
}
#recipe .list {
display: block; /* before Android4.3 iOS6:Safari */
display: -ms-flexbox; /*IE10*/
display: -webkit-flex; /* Safari */
display: flex;
-webkit-flex-wrap: wrap; /* Safari */
flex-wrap: wrap;
-webkit-justify-content: space-between; /* Safari */
justify-content: space-between;
margin-bottom: 40px;
@include media-sp { 
margin-bottom: 0;
}
}
#recipe .list article {
width: 31%;
@include media-sp { 
width: 100%;
margin: 0 0 25px 0 !important;
float: none;
}
}

#recipe .image:hover img {
opacity: 1;
-webkit-animation: flash 1.5s;
animation: flash 1.5s;
}
#recipe a {
color: #343333;
text-decoration: none;
transition: 0.3s;
}
#recipe a:hover {
color: #fff;
}
#recipe h3 {
font-size: 100%;
padding: 15px 0 8px 0;
@include media-tab { 
font-size: 90%;
padding: 10px 0 5px 0;
}
}
#recipe .bt a {
background: #fff;
}
#recipe .bt a:hover {
background: #f8f5db;
}
#recipe .bt a:hover {
color: #343333;
}

/************************************

BOTTOM

************************************/
#bottom {
padding: 70px 0;
@include media-tab { 

padding: 50px 0 20px 0;
}
}
#bottom .wrapper{
display: flex;
-webkit-flex-wrap: wrap; 
flex-wrap:  wrap;
justify-content: center;
}
#bottom article {
width: 46%;
margin:0 2% 40px;
@include media-sp { 
width: 94%;
margin: 0 auto 30px auto;
}

li{
margin:0 0 10px;
&:hover img {
opacity: 1;
-webkit-animation: flash 1.5s;
animation: flash 1.5s;
}
}
}

#bottom article .title{
display: inline-block;
}
#bottom .fb-page-waku .fb-page.fb_iframe_widget {
    display: block;
    position: relative;
}
#bottom .fb-page-waku .fb-page span{
width:100% !important;
}
#bottom .fb-page-waku .fb-page span iframe{
width:100% !important;
}

